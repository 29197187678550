import React from "react";

export const Navigation = (props) => {
  return (
    <nav id="menu" className="navbar navbar-default navbar-fixed-top">
      
      <div className="container" style={{display:'flex'}}>
      <img className="nav-logo" width='150' height='60' src="img/logo.png" alt="RAMPeX Logo"/>
        <div className="navbar-header">
          <button
            type="button"
            className="navbar-toggle collapsed"
            data-toggle="collapse"
            data-target="#bs-example-navbar-collapse-1"
          >
            {" "}
            <span className="sr-only">Toggle navigation</span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
            <span className="icon-bar"></span>{" "}
          </button>
          <a className="navbar-brand page-scroll" href="/#home">
            RAMPeX <p>TECHNOLOGIES</p>
          </a>{" "}
        </div>

        <div
          className="collapse navbar-collapse"
          id="bs-example-navbar-collapse-1"
        >
          <ul className="nav navbar-nav navbar-right">
            <li>
              <a href="/#features" className="page-scroll zoom">
                Features
              </a>
            </li>
            <li>
              <a href="/#about" className="page-scroll zoom">
                About
              </a>
            </li>
            <li>
              <a href="/#courses" className="page-scroll zoom">
                Courses
              </a>
            </li>
            <li>
              <a href="/#clients" className="page-scroll zoom">
                clients
              </a>
            </li>
            <li>
              <a href="/#testimonial" className="page-scroll zoom">
                Testimonials
              </a>
            </li>
            <li>
              <a href="/#team" className="page-scroll zoom">
                Team
              </a>
            </li>
            <li>
              <a href="/#contact" className="page-scroll zoom  ">
                Contact
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};
